<template>
  <div class="file-container">
    <div class="dl-box">
      <div class="dl-header">
        <div class="dl-header-tab">
<!--          文件-->
          <span @click="goBack(0)">{{ $t('FL') }}</span>
          >
          <span @click="goBack(1)">{{ fileItem.name }}</span>
        </div>
        <div class="dl-header-num">
          <div class="dl-num-type">{{ listTypeStr }}</div>
<!--          条数据 label.weixin.number.data-->
<!--          按上次修改日期排序 label.file.orderbylastmodifydate-->
          <div class="dl-num-txt">{{ listNum + ' ' + $t('label.weixin.number.data')}}  <span v-if="showTableIndex === '2'">· {{ $t('label.file.orderbylastmodifydate') }}</span></div>
        </div>
      </div>
      <div class="dl-table">
        <el-table
            v-if="showTableIndex === '1'"
            :data="listData"
            border
            height="565"
            style="width: 100%">
          <div slot="empty" style="height:200px;line-height: 200px;">

            <i class="el-icon-warning-outline"></i>{{ $t('label.dashboard.noRecord') }}

          </div>
          <el-table-column
              align="center"
              type="index">
          </el-table-column>
<!--          类型 label.type-->
          <el-table-column
              prop="share_group"
              :label="$t('label.type')">
            <template slot-scope="scope">
              {{ roleConfig[scope.row.share_group] }}
            </template>
          </el-table-column>
<!--          名称 label_name-->
          <el-table-column
              prop="name"
              :label="$t('label.name')">
            <template slot-scope="scope">
              <span class="person-item" @click="gotoUesrPage(scope.row.shareTo || ownerid)">{{scope.row.name}}</span>
            </template>
          </el-table-column>
<!--          权限 label.limit-->
          <el-table-column
              prop="share_role"
              :label="$t('label.limit')">
            <template slot-scope="scope">
              {{ roleConfig[scope.row.share_role] }}
            </template>
          </el-table-column>
        </el-table>
        <el-table
            v-if="showTableIndex === '2'"
            :data="listData"
            border
            height="565"
            style="width: 100%">
          <div slot="empty" style="height:200px;line-height: 200px;">

            <i class="el-icon-warning-outline"></i>{{ $t('label.dashboard.noRecord') }}

          </div>
          <el-table-column
              align="center"
              type="index">
          </el-table-column>
<!--          版本号 label.appupload.upload.version-->
          <el-table-column
              prop="version"
              :label="$t('label.appupload.upload.version')">
            <template slot-scope="scope">
              <div class="history-item" @click="downloadOldVer(scope.row.id)">
                {{scope.row.version}}
              </div>
            </template>
          </el-table-column>
<!--          内容修改者 label.file.versionedit-->
          <el-table-column
              prop="name"
              :label="$t('label.file.versionedit')">
            <template slot-scope="scope">
              <span class="person-item" @click="gotoUesrPage(scope.row.upload_user_id)">{{scope.row.name}}</span>
            </template>
          </el-table-column>
<!--          上次修改日期 label.file.lastmodifydate-->
          <el-table-column
              prop="createdate"
              :label="$t('label.file.lastmodifydate')">
            <template slot-scope="scope">
              {{scope.row.createdate|datetimeFormat(countryCode)}}
            </template>
          </el-table-column>
<!--          原因 label.reason-->
          <el-table-column
              prop="message"
              :label="$t('label.reason')"
              :show-overflow-tooltip='true'>
          </el-table-column>
        </el-table>
        <el-table
            v-if="showTableIndex === '3'"
            :data="listData"
            border
            height="565"
            style="width: 100%">
          <div slot="empty" style="height:200px;line-height: 200px;">

            <i class="el-icon-warning-outline"></i>{{ $t('label.dashboard.noRecord') }}

          </div>
          <el-table-column
              align="center"
              type="index">
          </el-table-column>
<!--          头像-->
          <el-table-column
              prop="icon"
              :label="$t('vue_label_file_user_header_pic')">
            <template>
              <svg class="icon" aria-hidden="true"> <use :href="allIcon['guanzhuzhe']"></use> </svg>
            </template>
          </el-table-column>
<!--          姓名 label.quote.xingming-->
          <el-table-column
              prop="name"
              :label="$t('label.quote.xingming')">
            <template slot-scope="scope">
              <span class="person-item" @click="gotoUesrPage(scope.row.user_id)">{{scope.row.name}}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import * as fileApi from "./api.js";
import * as Time from "@/utils/time";
export default {
  name: "detailList",
  data() {
    return {
      showTableIndex: 0,
      fileId: this.$route.query.id,
      listType: this.$route.query.type,
      ownerid: this.$route.query.owner,
      listTypeStr: '',
      fileItem: {},
      loading: false,
      listNum: 0,
      listData: [],
      roleConfig: {
        // person: '个人',
        // group: '小组',
        // check: '只读',
        // cooperation : '读写',
        // owner: '所有人',
        person: this.$i18n.t('label.chat.likeperson'),
        group: this.$i18n.t('label.ccchat.group.section'),
        check: this.$i18n.t('label.readonly'),
        cooperation : this.$i18n.t('label.readwrite'),
        owner: this.$i18n.t('label.file.owner'),
      },
      dateFormat: Time.dateFormat(this.$cookies.get('countryCode')),
      datetimeFormat: Time.datetimeFormat(this.$cookies.get('countryCode')),
      timeFormat: Time.timeFormat(this.$cookies.get('countryCode')),
      countryCode:this.$cookies.get('countryCode'),
    }
  },
  mounted() {
    document.title = this.$setTitle(this.$i18n.t('FL'));
    this.initPage()
  },
  methods: {
    initPage() {
      this.fileDetail(this.fileId)
      this.getListType(this.listType)
    },
    gotoUesrPage (id) {
      this.$router.push({
        path: `/commonObjects/detail/${id}/DETAIL`,
      });
    },
    // 获取type
    getListType(type) {
      this.showTableIndex = type
      // '分享者' label.file.sharewith
      // '历史版本'
      // '关注者'
      if (type === '1') {
        this.listTypeStr = this.$i18n.t('label.file.sharewith')
        this.getShareRoles(this.fileId)
      } else if (type === '2'){
        this.listTypeStr = this.$i18n.t('vue_label_file_history_version')
        this.getVersionHis(this.fileId)
      } else if (type === '3'){
        this.listTypeStr = this.$i18n.t('vue_label_CCChat_followers')
        this.getfollowList(this.fileId)
      }
    },
    // 获取共享列表
    getShareRoles(id) {
      fileApi.getShareList({id:id}).then(res=>{
        this.listNum = res.data.shareList.length
        this.listData = res.data.shareList
      })
    },
    // 获取历史版本
    getVersionHis(id) {
      fileApi.versionHistory({id:id, type: 'all'}).then(res=>{
        this.listNum = res.data.length
        this.listData = res.data
      })
    },
    // 获取关注列表
    getfollowList(id) {
      fileApi.getFollow({id: id, type: 'all'}).then(res=>{
        this.listNum = res.data.length
        this.listData = res.data
      })
    },
    // 获取详情
    fileDetail(id) {
      this.loading = true
      fileApi.getFileDetail({
        id: id
      }).then(res=>{
        this.fileItem = res.data[0] || {}
        this.loading = false
      })
    },
    downloadOldVer(id) {
      const baseURL = this.$baseConfig.baseURL
      const token = this.$CCDK.CCToken.getToken()
      const link = document.createElement('a')
      link.setAttribute("download", "")
      link.href = `${baseURL}/file/downloadFile?accessToken=${token}&id=${this.fileId}&versonId=${id}`

      link.click()
    },
    goBack (flag) {
      if (flag < 1) {
        // this.$router.push({
        //   path: `/ccFileCenter`,
        // });
        this.$router.go(-2)
      } else {
        this.$router.go(-1)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.file-container{
  border-left: none;
  border-right: none;
  border-radius: 3px;
  position: relative;
  height: 100%;
  padding: 10px 10px 10px 10px;
  .dl-box{
    border-radius: 3px;
    border: 1px solid #dedcda;
    // height: 100%;
    background: #f5f5f5;
    .dl-header{
      width: 100%;
      padding: 0 10px;
      box-sizing: border-box;
      .dl-header-tab{
        height: 34px;
        line-height: 34px;
        >span{
          cursor: pointer;
        }
        >span:hover{
          color: blue;
        }
      }
      .dl-header-num{
        height: 40px;
        color: #333;
        .dl-num-type{
          height: 20px;
          font-size: 16px;
          font-weight: bold;
        }
        .dl-num-txt{
          height: 20px;
          font-size: 12px;
        }
      }
    }
    .dl-table{
      //min-height: 526px;
      height: calc(100% - 74px);;
      background-color: #fff;
    }
  }
}
::v-deep .el-table th.is-leaf {
  color: #666;
  border-bottom: 1px solid #dedcda;
}
::v-deep .el-table--border td {
  border-right: none;
}
::v-deep .el-table td {
  position: relative;
  padding: 9px 0;
  color: #080707;
  border-bottom: 1px solid #dedcda;
}
::v-deep .el-table__header tr th {
  background: #fafaf9;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.history-item {
  cursor: pointer;
}
.history-item:hover{
  color: rgb(0, 109, 204);
}
.person-item {
  cursor: pointer;
}
.person-item:hover{
  color: rgb(0, 109, 204);
}
</style>
